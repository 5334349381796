import React from 'react'

import Page from '../../components/page'
import withRoot from '../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
} from '@material-ui/core'

import { WarningNote, SuccessNote } from '../../components/highlight'
import install01 from '../../static/fitface-install/01.jpg'
import install02 from '../../static/fitface-install/02.jpg'
import install03 from '../../static/fitface-install/03.jpg'
import install04 from '../../static/fitface-install/04.jpg'
import install05 from '../../static/fitface-install/05.jpg'
import install06 from '../../static/fitface-install/06.jpg'
import install07 from '../../static/fitface-install/07.jpg'
import install08 from '../../static/fitface-install/08.jpg'

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  parag: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  col: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '45%',
  },
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: '5px',
  }
})

@withRoot
@withStyles(styles)
export default class Install extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    return (
      <Page toggleTheme={this.props.toggleTheme} title="Install our FitFace clockface">
        <Typography className={classes.parag}>
          Install our free clockface from the <a className={classes.link} href="https://gallery.fitbit.com/details/0799c9f7-8aa4-4795-9af2-8f8087654321" target="_blank" rel="noopener noreferrer">Fibit gallery</a>
        </Typography>
        <img src={install01} className={classes.img} />

        <Typography className={classes.parag}>
          Make sure to give all the permissions, otherwise some features won&apos;t work correctly.
        </Typography>
        <img src={install02} className={classes.img} />

        <Typography className={classes.parag}>
          Once the clockface is installed, open its settings.
        </Typography>
        <img src={install03} className={classes.img} />

        <Typography className={classes.parag}>
          Enter your FitFace login and press <b>Login</b>.
        </Typography>
        <img src={install04} className={classes.img} />

        <Typography className={classes.parag}>
          You will be able to choose a design from yours, the free ones you put in your favourites and your purchases.
        </Typography>
        <img src={install07} className={classes.img} />

        <Typography className={classes.parag}>
          Select a design to display on your watch.
        </Typography>
        <img src={install06} className={classes.img} />

        <Typography className={classes.parag}>
          You will feel a small vibration, a loading screen will appear...<br />
          And after a few seconds, you will be able to enjoy the design on your wrist!
        </Typography>
        <WarningNote>If the design contains lots of images, the transfer can take some time, please be patient.</WarningNote>
        <SuccessNote>
          Your design is now installed. Enjoy!<br />
          You can change it at any time.
        </SuccessNote>

        <Typography variant="h3" align="center" color="secondary">The Settings</Typography>
        <Typography className={classes.parag}>
          From the clockface&apos;s settings page, you will be able to choose a design and configure it (theme color, images).<br />
          You can also set the global settings that apply for all the designs.
        </Typography>
        <img src={install05} className={classes.img} />

        <Typography className={classes.parag}>
          The global settings let you configure the temperature unit.<br />
          By default it&apos;s automatically detected from your Fitbit units preference.<br />
          If you use the metric system, it will use celius degree otherwise it will use fahrenheit degree.<br />
          But you can override this by forcing the unit you want.
        </Typography>
        <img src={install08} className={classes.img} />
      </Page>
    )
  }
}
